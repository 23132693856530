import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from '../../components';
import {
  getGoogleSignupUrl,
  setSinginLoading,
  signInWithGoogle,
} from '../../store/google2/google2.actions';
import { RootState } from '../../store/store';
import { ROUTES } from '../../routes';
import { linkChevron } from '../SelfSignup/Signup/assets';
import { ACCOUNT_URL } from '../../constants/config';
import { TokenError } from './TokenError/TokenError';

export const CreateAccount: React.FC = (): JSX.Element => {
  const { authCode, user, isSignInLoading, authError, googleUrl } = useSelector(
    (state: RootState) => state.google2
  );

  // const { search } = useLocation();

  // const token = new URLSearchParams(search).get('token') || '';

  const { token } = useParams<{ token: string }>();

  const [googleAuthCode, setGoogleAuthCode] = useState('');

  const dispatch = useDispatch();

  const history = useHistory();

  // eslint-disable-next-line
  window.onmessage = (event: any) => {
    if (event.data && event.data?.itsAMe) {
      setGoogleAuthCode(event.data.googleAuthCodeToSignUp);
    }
  };

  useEffect(() => {
    localStorage.removeItem('selfSignup');
    localStorage.removeItem('selfSignupInterrupted');
  }, []);

  useEffect(() => {
    if (!authError && !user && googleAuthCode?.length && !authCode) {
      dispatch(signInWithGoogle(googleAuthCode));
    }
    // eslint-disable-next-line
  }, [googleAuthCode, authError, user, dispatch]);

  useEffect(() => {
    dispatch(getGoogleSignupUrl(token));
  }, [token]); // eslint-disable-line

  useEffect(() => {
    let redirectTimer: NodeJS.Timeout;

    if (authCode && googleAuthCode) {
      if (token?.length) {
        redirectTimer = setTimeout(() => {
          dispatch(setSinginLoading(false));
          history.push(ROUTES.selfSignup.addExtension);
        }, 1500);
      } else {
        dispatch(setSinginLoading(false));
        window.location.href = `${ACCOUNT_URL}?authCode=${authCode}`;
      }
    }

    return () => {
      clearTimeout(redirectTimer);
    };

    // eslint-disable-next-line
  }, [authCode, user, googleAuthCode]);

  const clickContinue = () => {
    const googleWindowHeight = window.innerHeight / 2.2;

    const leftPosition = window.innerWidth / 2 - 685;

    const topPosition = -window.innerHeight + googleWindowHeight / 2.5;

    if (googleUrl?.length) {
      window.open(
        googleUrl,
        '_blank',
        `top=${topPosition} left=${leftPosition} width=520 height=${googleWindowHeight}`
      );
    }
  };

  // if (!token || error) return <Redirect to="/" />;

  const goToWebsite = () => {
    window.location.href = 'https://www.substrata.me/';
  };

  const openContactCenter = () => {
    window.open('https://www.substrata.me/contact', '_blank');
  };

  const openTermsOfUse = () => {
    window.open('https://www.substrata.me/terms-of-service/', '_blank');
  };

  const openPrivacyPolicy = () => {
    window.open('https://www.substrata.me/privacy-policy/', '_blank');
  };

  const attrs = {
    wrapper: {
      className: 'create-account',
    },

    background: {
      className: 'create-account-background-img',
    },

    contentWrapper: {
      className: 'create-account__content',
    },

    content: {
      wrapper: {
        className: 'signup',
      },

      leftSection: {
        wrapper: {
          className: 'signup__left-section',
        },

        linkBack: {
          wrapper: {
            className: 'signup__left-section__link-back',
            onClick: goToWebsite,
          },

          chevron: {
            className: 'signup__left-section__link-back-chevron',
            src: linkChevron,
          },
        },

        content: {
          wrapper: {
            className: 'signup__left-section__content',
          },

          row: {
            className: 'signup__left-section__content-row',
          },

          google: {
            wrapper: {
              className: 'signup__left-section__content__google',
            },

            button: {
              className: 'signup__left-section__content__google-button',
              onClick: clickContinue,
              isGoogleSignIn: true,
              googleLightVariant: true,
              disabled: isSignInLoading,
            },

            contact: {
              wrapper: {
                className: 'signup__left-section__content__google__contact',
              },

              text: {
                className:
                  'signup__left-section__content__google__contact-text',
              },

              link: {
                className:
                  'signup__left-section__content__google__contact-link',
                onClick: openContactCenter,
              },
            },
          },
        },

        footer: {
          wrapper: {
            className: 'signup__left-section__footer',
          },

          text: {
            wrapper: {
              className: 'signup__left-section__footer__text',
            },

            links: {
              termsOfUse: {
                className: 'signup__left-section__footer__text-link',
                onClick: openTermsOfUse,
              },

              privacyPolicy: {
                className: 'signup__left-section__footer__text-link',
                onClick: openPrivacyPolicy,
              },
            },
          },
        },
      },

      rightSection: {
        wrapper: {
          className: 'signup__right-section',
        },

        title: {
          className: 'signup__right-section-title',
        },
      },
    },
  };

  return (
    <div {...attrs.wrapper}>
      <TokenError />
      <div {...attrs.background} />
      <div {...attrs.contentWrapper}>
        <div {...attrs.content.wrapper}>
          <div {...attrs.content.leftSection.wrapper}>
            <div {...attrs.content.leftSection.content.google.wrapper}>
              <Button {...attrs.content.leftSection.content.google.button}>
                Continue with Google
              </Button>
              <div
                {...attrs.content.leftSection.content.google.contact.wrapper}
              >
                <div {...attrs.content.leftSection.content.google.contact.text}>
                  No Google account?
                </div>
                <div {...attrs.content.leftSection.content.google.contact.link}>
                  Contact us
                </div>
              </div>
            </div>
            <div {...attrs.content.leftSection.footer.wrapper}>
              <div {...attrs.content.leftSection.footer.text.wrapper}>
                By signing up with Google, you agree to our{' '}
                <div
                  {...attrs.content.leftSection.footer.text.links.termsOfUse}
                >
                  Terms of Service
                </div>{' '}
                and{' '}
                <div
                  {...attrs.content.leftSection.footer.text.links.privacyPolicy}
                >
                  Privacy Policy
                </div>
              </div>
            </div>
          </div>
          <div {...attrs.content.rightSection.wrapper} />
        </div>
      </div>
    </div>
  );
};

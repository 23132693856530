import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { clearLoginErrors } from '../../../../store/login/login.actions';
import { attentionIcon, closeIcon } from './assets';

export const ErrorModal: React.FC = (): JSX.Element => {
  const { authError } = useSelector((state: RootState) => state.login);

  const dispatch = useDispatch();

  const onCloseModal = () => {
    dispatch(clearLoginErrors());
  };

  const attrs = {
    wrapper: {
      className: 'error-modal',
    },

    overlay: {
      className: 'error-modal-overlay',
    },

    window: {
      wrapper: {
        className: 'error-modal__window',
      },

      closeIcon: {
        src: closeIcon,
        className: 'error-modal__window-close-icon',
        onClick: onCloseModal,
      },

      title: {
        wrapper: {
          className: 'error-modal__window__title',
        },

        icon: {
          src: attentionIcon,
          className: 'error-modal__window__title-icon',
        },
      },

      content: {
        wrapper: {
          className: 'error-modal__window__content',
        },

        firstWord: {
          className: 'error-modal__window__content-first-word',
        },

        link: {
          className: 'error-modal__window__content-link',
          href: 'mailto:contact@substrata.me',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  };

  const content = authError ? (
    <div {...attrs.wrapper}>
      <div {...attrs.overlay} />
      <div {...attrs.window.wrapper}>
        <img {...attrs.window.closeIcon} alt="" />
        <div {...attrs.window.title.wrapper}>
          <img {...attrs.window.title.icon} alt="" /> Could not log you in.
        </div>
        <div {...attrs.window.content.wrapper}>
          <span {...attrs.window.content.firstWord}>This </span>
          email address is either not associated with a user or it has not been
          activated. To get started, please reach out to{' '}
          <a {...attrs.window.content.link}>contact@substrata.me</a>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );

  return content;
};

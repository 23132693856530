export const MODE: string = process.env.REACT_APP_MODE || 'local';

let apiLink = '';

switch (MODE) {
  case 'prod':
    apiLink = 'https://beta-api.substrata.me';
    break;
  case 'stage':
    apiLink = 'https://staging.beta-api.substrata.me';
    break;
  default:
    apiLink = 'http://localhost:8002';
    break;
}

export const API_URL: string = process.env.REACT_APP_API_URL || apiLink;

export const EXTENSION_ID: string =
  process.env.REACT_APP_EXTENSION_ID || 'okeemaalpjcfeggfnahbeoagboahbbka';

export const backupExtensionId = 'flpomgapjbhfaidjjhdkppgfhbipkhgn';

let accountUrl = '';

switch (MODE) {
  case 'prod':
    accountUrl = 'https://account.substrata.me';
    break;
  case 'stage':
    accountUrl = 'https://staging.account.substrata.me';
    break;
  default:
    accountUrl = 'http://localhost:3003';
    break;
}

export const ACCOUNT_URL: string =
  process.env.REACT_APP_ACCOUNT_URL || accountUrl;

let superAccountUrl = '';

switch (MODE) {
  case 'prod':
    superAccountUrl = 'https://admin.substrata.me';
    break;
  case 'stage':
    superAccountUrl = 'https://staging.admin.substrata.me';
    break;
  default:
    superAccountUrl = 'http://localhost:3004';
    break;
}

export const SUPER_ACCOUNT_URL: string =
  process.env.REACT_APP_SUPER_ACCOUNT_URL || superAccountUrl;

let signupUrl = '';

switch (process.env.REACT_APP_MODE) {
  case 'prod':
    signupUrl = 'https://signup.substrata.me';
    break;
  case 'stage':
    signupUrl = 'https://staging.signup.substrata.me';
    break;
  default:
    signupUrl = 'http://localhost:3002';
    break;
}

export const SIGNUP_URL: string = process.env.REACT_APP_SIGNUP_URL || signupUrl;

let mixPanelToken = '';

switch (MODE) {
  case 'prod':
    mixPanelToken = '5c1b399da91785f377e01401f7660db7';
    break;
  case 'stage':
    mixPanelToken = 'a10f3438f40978dcfdf3e97dbda8c55a';
    break;
  default:
    mixPanelToken = '512032c4a184cd53742c05bd87f99e8e';
    break;
}

export const MIX_PANEL_TOKEN: string = mixPanelToken;

let ga4MeasurementId = '';

switch (MODE) {
  case 'prod':
    ga4MeasurementId = 'G-LV0R3Z2N71';
    break;
  case 'stage':
    ga4MeasurementId = 'G-Y4SW101C8D';
    break;
  default:
    ga4MeasurementId = 'G-M6H20P64DT';
    break;
}

export const GA4_MEASUREMENT_ID: string = ga4MeasurementId;

let bluesnapSku = '';

switch (MODE) {
  case 'prod':
    bluesnapSku = '4068068';
    break;
  case 'stage':
    bluesnapSku = '2894667';
    break;
  default:
    bluesnapSku = '2881111';
    break;
}

export const BLUESNAP_SKU: string = bluesnapSku;

let bluesnapStoreId = '';

switch (MODE) {
  case 'prod':
    bluesnapStoreId = '639609';
    break;
  case 'stage':
    bluesnapStoreId = '52832';
    break;
  default:
    bluesnapStoreId = '52832';
    break;
}

export const BLUESNAP_STOREID: string = bluesnapStoreId;

let stripePromiseId = '';

switch (MODE) {
  case 'prod':
    stripePromiseId =
      'pk_live_51LSQqxK6D0U3ayUuTpAiBvR3xHfdU0VjF4gYk2eXU9w9d3L5YwaC062guRY4QEtSuAN8U8vu5US1pp8kQvQwbKmH00Up08pMQ3';
    break;
  case 'stage':
    stripePromiseId =
      'pk_test_51LSQqxK6D0U3ayUuF0JHHGtOFbwju7AVfaYhCeCe78EIlufTRiVQHywXQQkHpNurHOfYzOLqfpK1ERmpQQ9Tp5F900hKHU61UI';
    break;
  default:
    stripePromiseId = 'pk_test_FwlyhLFtZ671W5JBIXfVqpB400jJdn7L19';
    break;
}

export const STRIPE_PROMISE_ID: string = stripePromiseId;

let starterProductId = '';

switch (MODE) {
  case 'prod':
    starterProductId = 'prod_P60PZ4uwIXinB1';
    break;
  case 'stage':
    starterProductId = 'prod_P1VkHVEVW261n6';
    break;
  default:
    starterProductId = 'prod_Ov4k7v8K9FPlOv';
    break;
}

export const STRIPE_STARTER_PRODUCT_ID: string = starterProductId;

let starterMonthlyPriceId = '';

switch (MODE) {
  case 'prod':
    starterMonthlyPriceId = 'price_1OHoOJK6D0U3ayUutASlQP2x';
    break;
  case 'stage':
    starterMonthlyPriceId = 'price_1ODSiqK6D0U3ayUuenZqfl78';
    break;
  default:
    starterMonthlyPriceId = 'price_1O7Eb9Fdt5z8oCFQFwJOdf0y';
    break;
}

export const STRIPE_STARTER_MONTHLY_PRICE_ID: string = starterMonthlyPriceId;

let starterYearlyPriceId = '';

switch (MODE) {
  case 'prod':
    starterYearlyPriceId = 'price_1OHoOJK6D0U3ayUuK5j5q1Qr';
    break;
  case 'stage':
    starterYearlyPriceId = 'price_1ODSiqK6D0U3ayUucYr7Ropt';
    break;
  default:
    starterYearlyPriceId = 'price_1O7Eb9Fdt5z8oCFQ0qUjio8O';
    break;
}

export const STRIPE_STARTER_YEARLY_PRICE_ID: string = starterYearlyPriceId;

let profProductId = '';

switch (MODE) {
  case 'prod':
    profProductId = 'prod_P60WKOIvun1yYZ';
    break;
  case 'stage':
    profProductId = 'prod_P1ViJMvw9cF3uP';
    break;
  default:
    profProductId = 'prod_Ov4nadRQjYMuY9';
    break;
}

export const STRIPE_PROF_PRODUCT_ID: string = profProductId;

let profMonthlyPriceId = '';

switch (MODE) {
  case 'prod':
    profMonthlyPriceId = 'price_1OHoV8K6D0U3ayUuPpFTUQIg';
    break;
  case 'stage':
    profMonthlyPriceId = 'price_1ODShQK6D0U3ayUuMseI3M8w';
    break;
  default:
    profMonthlyPriceId = 'price_1O7EdZFdt5z8oCFQOlwpJ0qu';
    break;
}

export const STRIPE_PROF_MONTHLY_PRICE_ID: string = profMonthlyPriceId;

let profYearlyPriceId = '';

switch (MODE) {
  case 'prod':
    profYearlyPriceId = 'price_1OHoV8K6D0U3ayUu9XHSkySe';
    break;
  case 'stage':
    profYearlyPriceId = 'price_1ODShQK6D0U3ayUuV55JHNl1';
    break;
  default:
    profYearlyPriceId = 'price_1O7EdZFdt5z8oCFQIrdqVK46';
    break;
}

export const STRIPE_PROF_YEARLY_PRICE_ID: string = profYearlyPriceId;

let scaleProductId = '';

switch (MODE) {
  case 'prod':
    scaleProductId = 'prod_P60U6De17QGvgV';
    break;
  case 'stage':
    scaleProductId = 'prod_P1VTxUKQXfjn4m';
    break;
  default:
    scaleProductId = 'prod_Ov4o5cjxQxQ1gy';
    break;
}

export const STRIPE_SCALE_PRODUCT_ID: string = scaleProductId;

let scaleMonthlyPriceId = '';

switch (MODE) {
  case 'prod':
    scaleMonthlyPriceId = 'price_1OHoT3K6D0U3ayUuCDc9kZME';
    break;
  case 'stage':
    scaleMonthlyPriceId = 'price_1ODSS7K6D0U3ayUu16FTMFTV';
    break;
  default:
    scaleMonthlyPriceId = 'price_1O7EetFdt5z8oCFQSZk7NfA0';
    break;
}

export const STRIPE_SCALE_MONTHLY_PRICE_ID: string = scaleMonthlyPriceId;

let scaleYearlyPriceId = '';

switch (MODE) {
  case 'prod':
    scaleYearlyPriceId = 'price_1OHoT3K6D0U3ayUuIEjM3dm5';
    break;
  case 'stage':
    scaleYearlyPriceId = 'price_1ODSS8K6D0U3ayUucDSGLe7f';
    break;
  default:
    scaleYearlyPriceId = 'price_1O7EetFdt5z8oCFQL8St4pVb';
    break;
}

export const STRIPE_SCALE_YEARLY_PRICE_ID: string = scaleYearlyPriceId;

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { MODE } from '../constants/config';
import { authReducer, AuthState } from './auth/auth.reducer';
import { loginReducer, LoginState } from './login/login.reducer';
import { Google2State, google2Reducer } from './google2/google2.reducer';

export type RootState = {
  auth: AuthState;
  login: LoginState;
  google2: Google2State;
};

const rootReducer = combineReducers({
  auth: authReducer,
  login: loginReducer,
  google2: google2Reducer,
});

const getStore = () => {
  let store = createStore(rootReducer, applyMiddleware(thunk));
  if (MODE === 'local') {
    store = createStore(
      rootReducer,
      compose(
        applyMiddleware(thunk),
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ && // eslint-disable-line
          (window as any).__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line
      )
    );
  }
  return store;
};

export const store = getStore();

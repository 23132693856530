import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import {
  GetGoogleSignupUrlAction,
  SetChoosedPlanInfo,
  SetGAIsInitialized,
  SetGoogleSelfSignupUrl,
  SetMixPanelIsInitialized,
  SetPaymenStatus,
  SetStripeSubscription,
  SetTrialUsedStatus,
  UninstallExtensionAction,
  VerifiedTokenResponse,
  VerifyTokenToSignupAction,
  VerifyTokenToSignupFailureAction,
} from './auth.types';
import axios from '../../axios';

export const VERIFY_TOKEN_TO_SIGNUP = 'VERIFY_TOKEN_TO_SIGNUP';
export const VERIFY_TOKEN_TO_SIGNUP_FAILURE = 'VERIFY_TOKEN_TO_SIGNUP_FAILURE';
export const GET_GOOGLE_SIGN_UP_URL = 'GET_GOOGLE_SIGN_UP_URL';
export const USE_GOOGLE_CALLBACK = 'USE_GOOGLE_CALLBACK';
export const GOOGLE_CALLBACK_FAILURE = 'GOOGLE_CALLBACK_FAILURE';
export const SET_MIX_PANEL_IS_INITIALIZED = 'SET_MIX_PANEL_IS_INITIALIZED';
export const SET_GA_IS_INITIALIZED = 'SET_GA_IS_INITIALIZED';
export const UNINSTALL_EXTENSION = 'UNINSTALL_EXTENSION';

export const SET_GOOGLE_SS_URL = 'SET_GOOGLE_SS_URL';

export const SET_CHOOSED_PLAN_INFO = 'SET_CHOOSED_PLAN_INFO';

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';

export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';

export const SET_TRIAL_USED_STATUS = 'SET_TRIAL_USED_STATUS';

export const verifyTokenToSingup = (token: string, orgId: string) => async (
  dispatch: Dispatch<
    VerifyTokenToSignupAction | VerifyTokenToSignupFailureAction
  >
): Promise<void> => {
  try {
    const user: AxiosResponse<VerifiedTokenResponse> = await axios.get(
      `/orgs/${orgId}/signup/${token}`
    );

    if (user.data.payload) {
      dispatch({
        type: VERIFY_TOKEN_TO_SIGNUP,
        payload: { ...user.data.payload, token },
      });
    }
  } catch (e) {
    dispatch({
      type: VERIFY_TOKEN_TO_SIGNUP_FAILURE,
    });
  }
};

export const getGoogleSignupUrl = (token: string, orgId: string) => async (
  dispatch: Dispatch<GetGoogleSignupUrlAction>
): Promise<void> => {
  const resposne = await axios.get(`/orgs/${orgId}/google-login/${token}`);

  dispatch({
    type: GET_GOOGLE_SIGN_UP_URL,
    payload: resposne.data.payload.url,
  });
};

export const setMixpanelInitializedSuccess = (): SetMixPanelIsInitialized => ({
  type: SET_MIX_PANEL_IS_INITIALIZED,
});

export const getGoogleSelfSignupUrl = () => async (
  dispatch: Dispatch<SetGoogleSelfSignupUrl>
): Promise<void> => {
  try {
    const response = await axios.get('/signup/google/self');

    dispatch({
      type: SET_GOOGLE_SS_URL,
      payload: `${response.data.payload.url}`,
    });
  } catch (e) {
    // err
  }
};

// export const getGoogleSelfSignupUrl = () => async (
//   dispatch: Dispatch<SetGoogleSelfSignupUrl>
// ): Promise<void> => {
//   try {
//     const response = await axios.get('/signup/google/self');

//     dispatch({
//       type: SET_GOOGLE_SS_URL,
//       payload: `${response.data.payload.url}`,
//     });
//   } catch (e) {
//     // err
//   }
// };

export const uninstallExtension = (
  orgId: string,
  userId: string,
  dateNow: string,
  extensionVersion: string
) => async (dispatch: Dispatch<UninstallExtensionAction>): Promise<void> => {
  try {
    const body = {
      action: 'removing',
      date: dateNow,
      version: extensionVersion || '6.4.8',
    };

    await axios.post(
      `/services/orgs/${orgId}/users/${userId}/apps/chrome-extension/stats/installHistory`,
      body
    );

    dispatch({ type: UNINSTALL_EXTENSION });
  } catch (e) {
    // err
  }
};

export const setChoosedPlanInfo = (
  productId: string,
  priceId: string,
  price: string,
  seatsQty: string,
  paymentPeriod: string,
  name: string
): SetChoosedPlanInfo => ({
  type: SET_CHOOSED_PLAN_INFO,
  payload: { productId, priceId, seatsQty, price, paymentPeriod, name },
});

export const callCreateSubscription = (
  orgId: string,
  userId: string,
  productId: string,
  priceId: string,
  seatsQty: string
) => async (dispatch: Dispatch<SetStripeSubscription>): Promise<void> => {
  try {
    const body = {
      productId,
      priceId,
      quantity: seatsQty,
    };

    const token = localStorage.getItem('token');

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    await axios.post(
      `/services/billing/stripe/orgs/${orgId}/users/${userId}/subscription`,
      body
    );

    dispatch({
      type: CREATE_SUBSCRIPTION,
    });
  } catch (e) {
    // ERR
  }
};

export const checkPaymentStatus = (orgId: string, userId: string) => async (
  dispatch: Dispatch<SetPaymenStatus>
): Promise<void> => {
  try {
    await axios.get(
      `/services/billing/stripe/orgs/${orgId}/users/${userId}/status`
    );

    dispatch({
      type: SET_PAYMENT_STATUS,
      payload: 'complete',
    });
  } catch (e) {
    dispatch({
      type: SET_PAYMENT_STATUS,
      payload: 'incomplete',
    });
  }
};

export const getTrialUsedStatus = (orgId: string) => async (
  dispatch: Dispatch<SetTrialUsedStatus>
): Promise<void> => {
  try {
    const response = await axios.get(`/services/billing/orgs/${orgId}/trial`);

    dispatch({
      type: SET_TRIAL_USED_STATUS,
      payload: response.data.payload.payload,
    });
  } catch (e) {
    dispatch({
      type: SET_TRIAL_USED_STATUS,
      payload: false,
    });
  }
};

export const setGoogleAnalyticsInitializedSuccess = (): SetGAIsInitialized => ({
  type: SET_GA_IS_INITIALIZED,
});

// prettier-ignore
import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import axios from '../../axios';
import { backupExtensionId } from '../../constants/config';
import {
  GetGoogleSignupUrlAction,
  SetGoogleSelfSignupUrl,
  SetMixPanelIsInitialized,
  VerifiedTokenResponse,
  VerifyTokenToSignupAction,
  VerifyTokenToSignupFailureAction,
  ClearLoginErrors,
  ExtensionMessageLogin,
  GetGoogleChooseAccountUrlAction,
  GetGoogleSignInUrlAction,
  SignInFailureAction,
  SignInLoading,
} from './google2.types';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT = 'SIGN_OUT';
export const GET_GOOGLE_CHOOSE_ACCOUNT_URL = 'GET_GOOGLE_CHOOSE_ACCOUNT_URL';
export const GET_GOOGLE_SIGN_IN_URL = 'GET_GOOGLE_SIGN_IN_URL';
export const IS_GETTING_AUTH_CODE = 'IS_GETTING_AUTH_CODE';
export const AUTH_CODE_OBTAINED = 'AUTH_CODE_OBTAINED';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const EXTENSION_MESSAGE_LOGIN = 'LOGIN';
export const EXTENSION_MESSAGE_LOGOUT = 'LOGOUT';
export const SIGN_IN_LOADING = 'SIGN_IN_LOADING';
export const CLEAR_LOGIN_ERRORS = 'CLEAR_LOGIN_ERRORS';

export const VERIFY_TOKEN_TO_SIGNUP = 'VERIFY_TOKEN_TO_SIGNUP';
export const VERIFY_TOKEN_TO_SIGNUP_FAILURE = 'VERIFY_TOKEN_TO_SIGNUP_FAILURE';
export const GET_GOOGLE_SIGN_UP_URL = 'GET_GOOGLE_SIGN_UP_URL';
export const USE_GOOGLE_CALLBACK = 'USE_GOOGLE_CALLBACK';
export const GOOGLE_CALLBACK_FAILURE = 'GOOGLE_CALLBACK_FAILURE';
export const SET_MIX_PANEL_IS_INITIALIZED = 'SET_MIX_PANEL_IS_INITIALIZED';
export const SET_GA_IS_INITIALIZED = 'SET_GA_IS_INITIALIZED';
export const UNINSTALL_EXTENSION = 'UNINSTALL_EXTENSION';

export const SET_GOOGLE_SS_URL = 'SET_GOOGLE_SS_URL';

export const SET_CHOOSED_PLAN_INFO = 'SET_CHOOSED_PLAN_INFO';

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';

export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';

export const SET_TRIAL_USED_STATUS = 'SET_TRIAL_USED_STATUS';

export const verifyTokenToSingup = (token: string, orgId: string) => async (
  dispatch: Dispatch<
    VerifyTokenToSignupAction | VerifyTokenToSignupFailureAction
  >
): Promise<void> => {
  try {
    const user: AxiosResponse<VerifiedTokenResponse> = await axios.get(
      `/orgs/${orgId}/signup/${token}`
    );

    if (user.data.payload) {
      dispatch({
        type: VERIFY_TOKEN_TO_SIGNUP,
        payload: { ...user.data.payload, token },
      });
    }
  } catch (e) {
    dispatch({
      type: VERIFY_TOKEN_TO_SIGNUP_FAILURE,
    });
  }
};

export const getGoogleSignupUrl = (token: string) => async (
  dispatch: Dispatch<GetGoogleSignupUrlAction>
): Promise<void> => {
  let query = '?op=signin';
  if (token?.length) {
    query = `?op=signup&token=${token}`;
  }

  const resposne = await axios.get(`/api/v1/google/2/general-scopes${query}`);

  dispatch({
    type: GET_GOOGLE_SIGN_UP_URL,
    payload: resposne.data.payload.url,
  });
};

export const setMixpanelInitializedSuccess = (): SetMixPanelIsInitialized => ({
  type: SET_MIX_PANEL_IS_INITIALIZED,
});

export const getGoogleSelfSignupUrl = () => async (
  dispatch: Dispatch<SetGoogleSelfSignupUrl>
): Promise<void> => {
  try {
    const response = await axios.get('/signup/google/self');

    dispatch({
      type: SET_GOOGLE_SS_URL,
      payload: `${response.data.payload.url}`,
    });
  } catch (e) {
    // err
  }
};

export const setDefaultJWT = (jwt: string): void => {
  const checkedJwt = jwt.includes('Bearer') ? jwt : `Bearer ${jwt}`;

  axios.defaults.headers.common.Authorization = checkedJwt;
};

export const getExtensionAuthCode = async (): Promise<void> => {
  try {
    const call = await axios.get('/auth-code/get?client=extension');
    const { authCode } = call.data.payload;
    if (authCode && chrome?.runtime) {
      const message: ExtensionMessageLogin = {
        type: EXTENSION_MESSAGE_LOGIN,
        payload: {
          authCode,
        },
      };

      chrome.runtime.sendMessage(backupExtensionId, message, (response) => {
        if (response) {
          console.log('response from extension 131', response);
        } else {
          console.log('sendMesage error 133', chrome.runtime.lastError);
        }
      });
    }
  } catch (e) {
    // TODO handle error
    console.log('from catch getExtensionAuthCode 139', e);
  }
};

export const setSinginLoading = (bool: boolean): SignInLoading => ({
  type: SIGN_IN_LOADING,
  payload: bool,
});

export const clearLoginErrors = (): ClearLoginErrors => ({
  type: CLEAR_LOGIN_ERRORS,
});

export const getClientAndExtensionAuthCodes = (client = 'dashboard') => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch({ type: IS_GETTING_AUTH_CODE });

    const clientAuthCodeResponse = await axios.get(
      `/auth-code/get?client=${client}`
    );

    const { authCode: clientAuthCode } = clientAuthCodeResponse.data.payload;

    const extensionAuthCodeResponse = await axios.get(
      '/auth-code/get?client=extension'
    );

    const {
      authCode: extensionAuthCode,
    } = extensionAuthCodeResponse.data.payload;

    if (!clientAuthCode) {
      throw new Error('No client auth code');
    }

    if (!extensionAuthCode) {
      throw new Error('No extension auth code');
    }

    if (!chrome?.runtime) {
      throw new Error('No chrome runtime');
    }

    if (extensionAuthCode) {
      const message: ExtensionMessageLogin = {
        type: EXTENSION_MESSAGE_LOGIN,
        payload: {
          authCode: extensionAuthCode,
        },
      };

      chrome?.runtime?.sendMessage(backupExtensionId, message, (response) => {
        if (response) {
          console.log('response from extension 194', response);
        } else {
          console.log('sendMesage error 196', chrome.runtime.lastError);
        }
      });
    }

    dispatch({
      type: AUTH_CODE_OBTAINED,
      payload: { authCode: clientAuthCode },
    });
  } catch (e) {
    console.log(e);
  }
};

export const getGoogleChooseAccountUrl = () => async (
  dispatch: Dispatch<GetGoogleChooseAccountUrlAction>
): Promise<void> => {
  try {
    const response = await axios.get('/login/google');

    // '/api/v1/google/2/general-scopes'

    dispatch({
      type: GET_GOOGLE_CHOOSE_ACCOUNT_URL,
      payload: `${response.data.payload.url}`,
    });
  } catch (e) {
    // err
  }
};

export const getGoogleSignInUrl = (url: string) => async (
  dispatch: Dispatch<GetGoogleSignInUrlAction | SignInFailureAction>
): Promise<void> => {
  try {
    const response = await axios.get(`/api/v1/google/2/authenticate${url}`);

    console.log('response', response.data.payload.url);

    const checkScope = await axios.get(`${response.data.payload.url}`);

    console.log(
      'scope',
      checkScope.data.payload.url.includes('selfSignupInterrupted=1')
    );

    const isUserAccountAlreadyExists = response?.data?.payload?.url?.includes(
      'selfSignupInterrupted=1'
    );

    if (isUserAccountAlreadyExists) {
      localStorage.setItem('selfSignupInterrupted', 'true');
    }

    dispatch({
      type: GET_GOOGLE_SIGN_IN_URL,
      payload: `${checkScope.data.payload.url}`,
    });
  } catch (e) {
    localStorage.removeItem('googleAuthCode');

    dispatch({
      type: SIGN_IN_FAILURE,
    });
  }
};

export const signInWithGoogle = (
  googleAuthCode: string,
  signup?: 'signup'
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch({
      type: SIGN_IN_LOADING,
      payload: true,
    });

    const userResponse = await axios.get(
      `/api/v1/google/2/authenticate${googleAuthCode}`
    );

    const { user, token } = userResponse.data.payload;

    localStorage.setItem('refresh', token.refreshJWTToken);
    localStorage.setItem('token', token.jwt);
    localStorage.setItem('tokenExpiresAt', token.jwtExpiresAt);
    localStorage.removeItem('googleSignUpAuthCode');

    setDefaultJWT(`Bearer ${localStorage.getItem('token')}`);

    const getDashboardAuthCode = await axios.get(
      '/auth-code/get?client=dashboard'
    );

    const { authCode } = getDashboardAuthCode.data.payload;

    if (authCode?.length) {
      localStorage.setItem('authCode', authCode);
    }

    if (!signup) {
      await getExtensionAuthCode();
    }

    dispatch({
      type: SIGN_IN,
      payload: {
        user,
        authCode,
        token: token.jwt,
        tokenExpiresAt: token.jwtExpiresAt,
      },
    });
  } catch (e) {
    localStorage.removeItem('googleAuthCode');

    dispatch({
      type: SIGN_IN_FAILURE,
    });
  }
};

export const refreshToken = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    const refresh = localStorage.getItem('refresh');

    if (!refresh) return;

    const respose = await axios.post('/refresh-token', {
      refreshToken: refresh,
    });

    const user = respose.data.payload;

    localStorage.setItem('refresh', user.refreshJWTToken);
    localStorage.setItem('token', user.jwt);
    localStorage.setItem('tokenExpiresAt', user.jwtExpiresAt);

    setDefaultJWT(user.jwt);

    dispatch({
      type: REFRESH_TOKEN,
      payload: {
        user,
        token: user.jwt,
        tokenExpiresAt: user.jwtExpiresAt,
      },
    });
  } catch (e) {
    // catch error
  }
};

export const signOut = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    setDefaultJWT(`Bearer ${localStorage.getItem('token')}`);

    await axios.get('/logout');
  } catch (e) {
    // TODO dispatch failure catch 401
  } finally {
    localStorage.clear();

    setDefaultJWT('');

    dispatch({
      type: SIGN_OUT,
      payload: null,
    });
  }
};

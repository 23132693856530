import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { SIGNUP_URL } from '../../../constants/config';
import { getGoogleSignInUrl } from '../../../store/google2/google2.actions';
import { RootState } from '../../../store/store';

export const GoogleCallback: React.FC = (): JSX.Element => {
  const { error, user, googleSignInUrl } = useSelector(
    (state: RootState) => state.google2
  );

  const { search: googleAuthCodeToSignUp } = useLocation();

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !error &&
      !user &&
      googleAuthCodeToSignUp &&
      !localStorage.getItem('googleAuthCode') &&
      !googleSignInUrl
    ) {
      localStorage.setItem('googleAuthCode', googleAuthCodeToSignUp);
      dispatch(getGoogleSignInUrl(googleAuthCodeToSignUp));
    } else if (!error && !user && googleSignInUrl) {
      window.location.href = googleSignInUrl;
    } else if (
      !error &&
      !user &&
      !googleSignInUrl &&
      localStorage.getItem('googleAuthCode') !== googleAuthCodeToSignUp
    ) {
      localStorage.removeItem('googleAuthCode');
      if (window?.opener) {
        window.opener.postMessage(
          { googleAuthCodeToSignUp, itsAMe: true },
          `${SIGNUP_URL}/google-2`
        );
        window.close();
      }
    }
    // else if (error) history.push('/login');
  }, [error, user, dispatch, history, googleAuthCodeToSignUp, googleSignInUrl]);
  return <div />;
};

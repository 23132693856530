import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { AddExtension, CreateAccount } from './pages';
import { GoogleCallback } from './pages/CreateAccount/GoogleCallback/GoogleCallback';
import { ROUTES } from './routes';
import { CreateAccount as Google2CreateAccount } from './pages/Google2/CreateAccount';
import { GoogleCallback as Google2Callback } from './pages/Google2/GoogleCallback/GoogleCallback';
import { AddExtension as Google2AddExtension } from './pages/Google2/AddExtension/AddExtension';

interface RouteI {
  routeName: string;
  path: string;
  Component: React.FC;
}

interface RoutingI {
  [key: string]: RouteI;
}

export const SignupRoutes: RoutingI = {
  CREATE_ACCOUNT: {
    routeName: 'Create account',
    path: ROUTES.signup.createAccount,
    Component: CreateAccount,
  },
  GOOGLE_CALLBACK_SIGNUP: {
    routeName: 'Google callback',
    path: ROUTES.signup.googleSignup,
    Component: GoogleCallback,
  },
  ADD_EXTENSION: {
    routeName: 'Add extension',
    path: ROUTES.signup.addExtension,
    Component: AddExtension,
  },
  CREATE_ACCOUNT_G: {
    routeName: 'Create account g',
    path: ROUTES.google2.signinGoogle2,
    Component: Google2CreateAccount,
  },
  GOOGLE_CALLBACK_SIGNUP_G: {
    routeName: 'Google callback g',
    path: ROUTES.google2.signinGoogle2Callback,
    Component: Google2Callback,
  },
  ADD_EXTENSION_G: {
    routeName: 'Add extension g',
    path: ROUTES.google2.addExtensionGoogle2,
    Component: Google2AddExtension,
  },
};

const getRoutes = () =>
  Object.values(SignupRoutes).map(({ path, Component }) => (
    <Route key={path} exact path={path}>
      {({ match }) => (
        <CSSTransition
          in={match != null}
          timeout={400}
          classNames="slider"
          unmountOnExit
        >
          <Component />
        </CSSTransition>
      )}
    </Route>
  ));

export const SignupRouter: React.FC = (): JSX.Element => {
  const router = getRoutes();
  return (
    <>
      <TransitionGroup className="slider">
        <Switch>
          {router}
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </TransitionGroup>
    </>
  );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import attention from '../../CreateAccount/assets/attention-icon.svg';
import closeIcon from '../../CreateAccount/assets/close-modal-icon.svg';
import { clearLoginErrors } from '../../../store/google2/google2.actions';

export const TokenError: React.FC = (): JSX.Element => {
  const { authError } = useSelector((state: RootState) => state.google2);
  const dispatch = useDispatch();

  const onCloseModal = () => {
    dispatch(clearLoginErrors());
  };

  useEffect(() => {
    localStorage.removeItem('selfSignup');
    localStorage.removeItem('seats');

    if (authError) {
      localStorage.clear();
    }
  }, [authError]);

  const errorModal = authError ? (
    <>
      <div className="err-page__err-modal__overlay" />
      <div className="err-page__err-modal__window">
        <img
          src={closeIcon}
          className="err-page__err-modal__window__close-icon"
          alt=""
          onClick={onCloseModal}
        />
        <div className="err-page__err-modal__window__title">
          <img src={attention} alt="" /> Could not log you in.
        </div>
        <div className="err-page__err-modal__window__body">
          <span>This </span>
          email address is either not associated with a user or it has not been
          activated. To get started, please reach out to{' '}
          <a
            href="mailto:contact@substrata.me"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact@substrata.me
          </a>
        </div>
      </div>
    </>
  ) : (
    <></>
  );

  return errorModal;
};

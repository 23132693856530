import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { EXTENSION_ID, backupExtensionId } from '../../constants/config';
import {
  signOut,
  EXTENSION_MESSAGE_LOGOUT,
} from '../../store/login/login.actions';
import { ExtensionMessageLogout } from '../../store/login/login.types';
import { ROUTES } from '../../routes';

export const Logout: React.FC = (): JSX.Element => {
  const { token, refresh } = useSelector((state: RootState) => state.login);

  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const isGoogle2 = queryParams.get('google2');

  const dispatch = useDispatch();

  useEffect(() => {
    if (chrome?.runtime) {
      const message: ExtensionMessageLogout = {
        type: EXTENSION_MESSAGE_LOGOUT,
        payload: null,
      };

      const conditionalExtensionId = isGoogle2
        ? backupExtensionId
        : EXTENSION_ID;

      chrome?.runtime?.sendMessage(conditionalExtensionId, message, () => {});
    }

    if (token || refresh) {
      dispatch(signOut());
    }

    if (!token || !refresh) {
      if (isGoogle2) {
        window.location.href = '/google-2/signup';
      } else {
        window.location.href = ROUTES.selfSignup.homePage;
      }
    }
  });

  return <div />;
};

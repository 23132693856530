export const ROUTES = {
  login: '/login',
  logout: '/logout',
  googleLogin: '/google-callback/login',
  signup: {
    createAccount: '/orgs/:orgId/signup/:token',
    googleSignup: '/google-callback/signup',
    addExtension: '/add-extension',
  },
  google2: {
    signinGoogle2: '/google-2/signup/:path?/:orgid?/:signup?/:token?',
    signinGoogle2Callback: '/google-2/callback',
    addExtensionGoogle2: '/google-2/add-extension',
  },
  selfSignup: {
    signup: '/signup',
    googleSignup: '/signup/google-signup',
    pricing: '/signup/pricing',
    paymentDetails: '/signup/payment-details',
    processing: '/signup/processing',
    completeOrder: '/signup/complete-order',
    redirectParent: '/signup/order-completed',
    addExtension: '/signup/add-extension',
    thankYou: '/signup/thank-you',
    homePage: '/',
  },
  uninstall: '/q-uninstall',
};

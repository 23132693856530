import { Reducer } from 'redux';
import {
  GET_GOOGLE_SIGN_UP_URL,
  SET_GA_IS_INITIALIZED,
  SET_GOOGLE_SS_URL,
  SET_MIX_PANEL_IS_INITIALIZED,
  UNINSTALL_EXTENSION,
  VERIFY_TOKEN_TO_SIGNUP,
  VERIFY_TOKEN_TO_SIGNUP_FAILURE,
  GET_GOOGLE_SIGN_IN_URL,
  REFRESH_TOKEN,
  SIGN_OUT,
  AUTH_CODE_OBTAINED,
  IS_GETTING_AUTH_CODE,
  SIGN_IN,
  SIGN_IN_FAILURE,
  SIGN_IN_LOADING,
  CLEAR_LOGIN_ERRORS,
  GET_GOOGLE_CHOOSE_ACCOUNT_URL,
} from './google2.actions';
import { Google2Actions, VerifiedInformation } from './google2.types';
import { User } from '../login/types';

export type Google2State = {
  dataToSignUp: VerifiedInformation | null;
  error: boolean;
  googleUrl: string | null;
  googleSelfSignupUrl: string | null;
  isMixPanelInitialized: boolean;
  isGoogleAnalyticsInitialized: boolean;
  uninstallExtensionSent: boolean;
  user: User | null;
  googleChooseAccountUrl: string;
  googleSignInUrl: string;
  authCode: string;
  isGettingAuthCode: boolean;
  isSignInLoading: boolean;
  authError: boolean;
  token: string;
  tokenExpiresAt: string;
  refresh: string;
};

const initialState: Google2State = {
  dataToSignUp: null,
  error: false,
  googleUrl: null,
  googleSelfSignupUrl: null,
  isMixPanelInitialized: false,
  isGoogleAnalyticsInitialized: false,
  uninstallExtensionSent: false,
  user: null,
  googleChooseAccountUrl: '',
  googleSignInUrl: '',
  authCode: '',
  isGettingAuthCode: false,
  isSignInLoading: false,
  authError: false,
  token: localStorage.getItem('token') || '',
  tokenExpiresAt: localStorage.getItem('tokenExpiresAt') || '',
  refresh: localStorage.getItem('refresh') || '',
};

export const google2Reducer: Reducer<Google2State, Google2Actions> = (
  state: Google2State = initialState,
  action: Google2Actions
): Google2State => {
  switch (action.type) {
    case VERIFY_TOKEN_TO_SIGNUP:
      return {
        ...state,
        dataToSignUp: action.payload,
      };

    case GET_GOOGLE_SIGN_UP_URL:
      return {
        ...state,
        googleUrl: action.payload,
      };

    case SET_GOOGLE_SS_URL:
      return {
        ...state,
        googleSelfSignupUrl: action.payload,
      };

    case VERIFY_TOKEN_TO_SIGNUP_FAILURE:
      return {
        ...state,
        error: true,
      };

    case SET_MIX_PANEL_IS_INITIALIZED:
      return {
        ...state,
        isMixPanelInitialized: true,
      };

    case SET_GA_IS_INITIALIZED:
      return {
        ...state,
        isGoogleAnalyticsInitialized: true,
      };

    case UNINSTALL_EXTENSION:
      return {
        ...state,
        uninstallExtensionSent: true,
      };

    case SIGN_IN:
      return {
        ...state,
        user: action.payload.user,
        authCode: action.payload.authCode,
        token: action.payload.token,
        tokenExpiresAt: action.payload.tokenExpiresAt,
        authError: false,
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        authError: true,
        isSignInLoading: false,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        tokenExpiresAt: action.payload.tokenExpiresAt,
      };
    case GET_GOOGLE_CHOOSE_ACCOUNT_URL:
      return {
        ...state,
        googleChooseAccountUrl: action.payload,
      };
    case GET_GOOGLE_SIGN_IN_URL:
      return {
        ...state,
        googleSignInUrl: action.payload,
      };
    case SIGN_OUT:
      return {
        ...initialState,
        token: '',
        tokenExpiresAt: '',
        refresh: '',
      };
    case AUTH_CODE_OBTAINED:
      return {
        ...state,
        authCode: action.payload.authCode,
      };
    case IS_GETTING_AUTH_CODE:
      return {
        ...state,
        isGettingAuthCode: true,
      };
    case SIGN_IN_LOADING:
      return {
        ...state,
        isSignInLoading: action.payload,
      };
    case CLEAR_LOGIN_ERRORS:
      return {
        ...state,
        isGettingAuthCode: false,
        isSignInLoading: false,
        authError: false,
      };

    default:
      return state;
  }
};
